<template>
  <div class="bodycont">

    <comHeader />
    <div class="infodiv">
      <navTitle :navData='navData' />
      <div class="infocont">
        <div class="leftContent">
          <div class="bassMess fwsMess">
            <h5>{{dataInfo.Name}}</h5>
            <p v-if="dataInfo.Sex">性别:<span >{{dataInfo.Sex==1?'男':'女'}}</span></p>
            <p v-if="dataInfo.Age">出生日期:【<span >{{dataInfo.Age}}</span>】</p>
            <p>简介:<span >{{dataInfo.Introduction}}</span></p>
            <p class="picUrl"><img id="pic" :src="dataInfo.file" /></p>

           <!-- <p class="btnBox" v-if="!isshow" @click="changeleave">

              <el-button type="primary">我要留言</el-button>
            </p> -->


         <!--   <div v-if="isshow">

              <p class="ctitle">留言:</p>
              <el-input type="textarea" :rows="2" placeholder="请输入留言" v-model="textarea">
              </el-input>
              <div class="cbtn">
                <el-button size='mini' @click="changeleave" type="info">关闭</el-button>
                <el-button size='mini' type="primary">提交</el-button>
              </div>
            </div> -->
          </div>

          <div class="counter-box py-2 my-2">
            <div class="levelMess jsxmMess">
                <div class="levelTit">
                  <p>{{titleName}}介绍</p>
                  <div class="colorBox"></div>
                </div>
                <div class="desc">
                  <p class="title"> <span>{{titleName}}名称:</span> {{dataInfo.Name}}</p>
                  <p class="title"> <span>学科领域:</span> {{dataInfo.subjectList}}</p>
                  <p class="title"> <span>应用领域:</span> {{dataInfo.applicationList}}</p>
                  <p class="title"> <span>{{titleName}}研究方向:</span> {{dataInfo.Research}}</p>
                  <p class="title"> <span>{{titleName}}联系方式:</span> {{dataInfo.Tel}}</p>
                  <p class="title"> <span>{{titleName}}邮箱:</span> {{dataInfo.Email}}</p>
                  <p class="title"> <span>{{titleName}}地址:</span> {{dataInfo.Area}}</p>
                 
                  <div v-if='nameType==1'>
                    <p class="title"> <span>{{titleName}}最高学位:</span>{{dataInfo.degree}} </p>
                    <p class="title"> <span>{{titleName}}职称:</span>{{dataInfo.title}} </p>
                    <p class="title"> <span>{{titleName}}行业领域:</span>{{dataInfo.field}} </p>
                    <p class="title"> <span>{{titleName}}学科专业:</span>{{dataInfo.describe}} </p>
                    <p class="title"> <span>{{titleName}}标签:</span>{{dataInfo.tag}} </p>
                    <p class="title"> <span>{{titleName}}专家资源标签:</span>{{dataInfo.label}} </p>
                    <p class="title"> <span>{{titleName}}专家级别:</span>{{dataInfo.deptId}} </p>
                  </div>
                  <div v-if='nameType==4'>
                    <p class="title"> <span>{{titleName}}行业大类:</span>{{dataInfo.industryCategory}} </p>
                    <p class="title"> <span>{{titleName}}产业细分:</span>{{dataInfo.industrySegmentation}} </p>
                    <p class="title"> <span>{{titleName}}可提供资源/合作:</span>{{dataInfo.resCooperationAvailable}} </p>
                    <p class="title"> <span>企业荣誉:</span>{{dataInfo.corporateHonor}} </p>
                  </div>
                  <p class="title"> <span>{{titleName}}详情:</span> </p>
                  <div class="infodata" v-html='dataInfo.Detail'> </div>



                </div>

            </div>
          </div>
        </div>
      <!--  <div class="relate">
          <div class="xqRelate" style="" id="xgxq">
            <h5 class="allTit">相关需求推荐</h5>
            <ul id="relateXq">暂无 </ul>
          </div>

        </div> -->
      </div>
    </div>
    <bNav />
  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import navTitle from '@/components/navtitle.vue'
  import api from '@/config/api'
  import {
    getRequst,
    postRequest
  } from '@/api/index'
  export default {
    components: {
      comHeader,
      bNav,
      navTitle,

    },
    data() {
      return {
        isshow: false,
        textarea: '',
        navData: [],
        objectId: '',
        nameType: '',
        dataInfo:{},
        titleName:'',
      }
    },
    mounted() {
      this.navData = [{
          name: this.$route.query.name,
          url: this.$route.query.url,
        },
        {
          name: '详情页',
          url: 'teamDetails',
        },
      ]
      this.objectId = this.$route.query.id
      this.nameType = this.$route.query.name
      if (this.$route.query.name == '特聘专家') {
       this.nameType = 1
       this.titleName ='专家'
      } else if (this.$route.query.name == '科创机构') {
        this.nameType = 2
        this.titleName ='机构'
      } else if (this.$route.query.name== '创新团队') {
         this.nameType = 3
         this.titleName ='团队'
      }else if (this.$route.query.name== '园区企业') {
         this.nameType = 4
         this.titleName ='企业'
      }
      if (this.objectId) {
        this.getInfo()
      }
    },
    methods: {
      changeleave() {
        this.isshow = !this.isshow
      },
      //获取列表
      getInfo() {
        let data = {
          objectId: this.objectId
        }
        postRequest(api.teamselectById, data).then(res => {
          if (this.nameType ==1) {
            this.gettext(res.data)
          } else if (this.nameType == 2) {
            this.gettext2(res.data)
          } else if (this.nameType == 3) {
            this.gettext1(res.data)
          }else if (this.nameType == 4) {
            this.gettext3(res.data)
          }
        })
      },
      gettext(data) {
        this.dataInfo = {
          age:data.age,
          Age: data.expertsAge,
          Area:data.expertsArea,
          Detail: data.expertsDetail,
          Email: data.expertsEmail,
          Field: data.expertsField,
          Id:data.expertsId,
          Introduction:data.expertsIntroduction,
          Name:data.expertsName,
          Research: data.expertsResearch,
          Results: data.expertsResults,
          Sex: data.expertsSex,
          Tel: data.expertsTel,
          fieldId:data.fieldId,
          file: data.file,
          objectId:data.objectId,
           fieldName:data.fieldName,
           applicationList:data.applicationList,
           subjectList:data.subjectList,
           degree:data.degree,
           title: data.title,
           field:data.fieldList,
           describe:data.describeList,
           tag: data.tag,
           label:data.label,
            deptId:data.deptId,
        }
      },
      gettext1(data) {
        this.dataInfo = {
          Area:data.teamAdds,
          Detail: data.teamDetail,
          Email: data.teamEmail,
          Field: data.teamField,
          Id:data.teamId,
          Introduction:data.teamIntroduction,
          Name:data.teamName,
          Research: data.teamResearch,
          Results: data.teamResults,
          Tel: data.teamTel,
          fieldId:data.fieldId,
          file: data.file,
          objectId:data.objectId,
          fieldName:data.fieldName,
          applicationList:data.applicationList,
          subjectList:data.subjectList,
        }
      },
      gettext2(data) {
        this.dataInfo = {
          Area:data.organizationAdds,
          Detail: data.organizationDetail,
          Email: data.organizationEmail,
          Field: data.organizationField,
          Id:data.organizationId,
          Introduction:data.organizationIntroduction,
          Name:data.organizationName,
          Research: data.organizationResearch,
          Results: data.organizationResults,
          Sex: data.organizationSex,
          Tel: data.organizationTel,
          fieldId:data.fieldId,
          file: data.file,
          objectId:data.objectId,
          fieldName:data.fieldName,
          applicationList:data.applicationList,
          subjectList:data.subjectList,
        }
      },
      gettext3(data) {
        this.dataInfo = {
          Area:data.enterprisesAdds,
          Detail: data.enterprisesDetail,
          Email: data.enterprisesEmail,
          Field: data.enterprisesField,
          Id:data.enterprisesId,
          Introduction:data.enterprisesIntroduction,
          Name:data.enterprisesName,
          Research: data.enterprisesResearch,
          Results: data.enterprisesResults,
          Sex: data.enterprisesSex,
          Tel: data.enterprisesTel,
          fieldId:data.fieldId,
          file: data.file,
          objectId:data.objectId,
          fieldName:data.fieldName,
          applicationList:data.applicationList,
          subjectList:data.subjectList,
          industryCategory:data.industryCategoryList,
          industrySegmentation:data.industrySegmentation,
          resCooperationAvailable:data.resCooperationAvailable,
          corporateHonor:data.corporateHonor,

        }
      }


    }
  }
</script>

<style lang="less" scoped>
  .infodata{
    /deep/ p{
      margin-top: 20px;
    }
   /deep/ span{
    line-height: 30px;
   }
  }
  .infodiv {
    width:1190px;
    margin: 0 auto;
    min-height: 77vh  }

  .contimg {
    width: 300px;
    height: 300px;
  }

  .leftContent {
    width: 100%;
      margin: 0 auto;

    .counter-box {
      background: #fff;
      border-radius: 5px;
      position: relative;
      box-shadow: 0px 5px 14px 0 #ddd;
      color: #323232;
      margin-top: 20px;
    }

    .levelMess {
      width: 100%;
      overflow: hidden;
      margin-bottom: 20px;

      .levelTit {
        position: relative;
        height: 60px;

        p {
          width: 100%;
          height: 30px;
          line-height: 30px;
          margin: 20px 0;
          font-size: 22px;
          color: #323232;
          border-left: 6px solid #1277fd;
          padding-left: 26px;
          z-index: 2;
          vertical-align: top;
          position: absolute;
          left: 0;
          bottom: 0;
        }

        .colorBox {
          height: 10px;
          width: 68px;
          background: #c0d4fb;
          z-index: 1;
          position: absolute;
          left: 29px;
          bottom: 20px;
        }
      }
    }

    .counter-box {
      background: #fff;
      border-radius: 5px;
      position: relative;
      box-shadow: 0px 5px 14px 0 #ddd;
      color: #323232;
    }

    .showview {
      margin: 10px 30px;
    }

    .showview div[class*=col-sm] {
      color: #323232;
      font-size: 15px;
      padding: 8px 0;
    }

    .showview .title {
      color: #999;
    }

    .showview span {
      font-size: 15px;
      line-height: 25px;
    }
  }

  .tit {
    margin-bottom: 10px;
  }

  .messTable {

    img {
      width: 20px;
      height: 22px;
    }
  }

  .btnBox {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .ctitle {
    font-size: 16px;
    color: #000;
    margin-bottom: 6px;
  }

  .cbtn {
    float: right;
    margin-top: 10px;
  }

  .relate>div {
    border: 1px solid #ededed;
    margin-bottom: 40px;
  }

  .allTit {
    width: 100%;
    background: #f8f8f8;
    box-sizing: border-box;
    color: #000;
    font-size: 18px;
    height: 50px;
    line-height: 50px;
    font-weight: normal;
    padding: 0 20px;
  }

  .relate {
    width: 23%;
  }

  .relate ul {
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
    padding-top: 0;
  }

  .infocont {
    display: flex;
    justify-content: space-between;
  }

  .picUrl {
    img {
      width: 110px;
      height: 110px;
      border-radius: 50%;
    }

    width: 110px;
    height: 110px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 20px;
    right: 20px;
    text-align: center;
  }

  .bassMess {
    position: relative;
    background: #fff;
    padding: 24px;
    border-top: 6px solid #0e86d1;
    box-shadow: 0 0 20px #ddd;
    overflow: hidden;
    border-radius: 0 0 5px 5px;
    min-height:200px;

    h5 {
      color: #333;
      font-size: 24px;
      padding-bottom: 10px;
    }

    p {
      color: #333;
      font-size: 15px;
      padding: 8px 0;
    }
  }
  .desc{
    padding: 20px;
    .title{
      margin-bottom: 20px;

    }
  }

</style>
